export class FiltersEPCFilesTable {
  query?: string;
  fileGroup: string;
  fileGroupDetailId?: string;

  getTrimmedFilters(): FiltersEPCFilesTable {
    const trimmedFilters: FiltersEPCFilesTable = Object.assign(new FiltersEPCFilesTable(), {});

    if (this.query && this.query !== '') {
      trimmedFilters['query'] = this.query;
    }
    if (this.fileGroup && this.fileGroup !== '') {
      trimmedFilters['fileGroup'] = this.fileGroup;
    }
    if (this.fileGroupDetailId && this.fileGroupDetailId !== '') {
      trimmedFilters['fileGroupDetailId'] = this.fileGroupDetailId;
    }

    if (Object.keys(trimmedFilters).length > 0) {
      return trimmedFilters;
    } else {
      return null;
    }
  }
}
