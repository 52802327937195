import {EnvironmentInterface} from '@env/environment.interface';

declare const require: any;

export const environment: EnvironmentInterface = {
  isProduction: false,
  apiBaseUrl: 'https://test-epc.app-dysto.nl:443',
  apiPrefix: 'V1/webapp',
  useProxy: false,
  version: require('../../package.json').version,
  ws: 'https://test-epc.app-dysto.nl:443/hub',
  deprecatedEnvSuggestionUrl: null,
  redirectToApiOffLineAfterApiTimeOut: false,
  apiServerResponseTimeoutMs: 120000,
  features: {
    canUseAdvancedTicketFilters: true,
    canUseMails: true,
    canUseExternalFiles: true,
    canDownloadReports: true,
    hasSafetyModule: true,
    reservationItemsModule: true,
    hasInspectionFormsModule: true,
    hasDownloadsModule: true,
    hasStoringsmeldersModule: true,
    hasReserveringsItemsModule: true,
    canAddMaterialsOnTicketAndProjectDetailPages: true,
    hasRequestsModule: true,
    hasLinkLocationsModule: true,
    useFilesModule: true,
  }
};
